<template>
  <div class="footprint">
    <!-- 头部 -->
    <van-nav-bar
      title="足迹"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
      fixed
      :z-index="99"
      class="dark_bg"
    >
      <span
        class="iconfont icon-shanchu dark_text"
        slot="right"
        style="font-size: 0.5rem; color: black"
        @click="showDel = true"
      ></span>
    </van-nav-bar>
    <!-- 删除提示组件 -->
    <van-dialog
      v-model="showDel"
      show-cancel-button
      class="del-tip"
      @confirm="delFoots"
    >
      <div class="del-msg">
        <p>
          <img src="../../../../static/images/rubbish.png" alt />
        </p>
        <p>确认删除足迹吗</p>
      </div>
    </van-dialog>
    <!-- 内容 -->
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$globalConfig.finishedText"
      @load="onLoad"
      :error.sync="error"
      offset:100
    >
      <section class="foot_list">
        <div  v-for="list in footList" :key="list.title" class="data-center">
          <h2>{{list.title}}</h2>
                 
          <div class="data-list">
              <ul
                 v-for="item in list.footprintDTOList"
                 :key="item.id"
                @click="goGoodsDetail(item.spuId)"
               >
                <!-- @click="show(item)" -->
                 <li class="img-conterner">
                   <img v-lazy="item.spuImg" alt />
                 </li>
                 <li  class="price"><span class="price-img">￥</span>{{ item.showPrice }}</li>
                 <!-- <li class="score">{{ item.spuTitle }}</li> -->
               </ul>
          </div>
          <div class="gap"></div>
      
        </div>

      </section>
      <!-- <p slot="loading">
        <img
          src="../../../assets/images/index.messenger-typing-preloader.svg"
          alt
          style="width: 20%"
        />
      </p> -->
      <loading-infinity slot="loading"></loading-infinity>
    </van-list>
  </div>
</template>

<script>
import { NavBar, List, Dialog } from "vant";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      footList: [],
      loading: false,
      finished: false,
      error: false,
      currentPage: 1,
      totalPage: Number,
      goGoodsDetail: this.Base.goGoodsDetail,
      showDel: false,
      onLoads: false,
    };
  },
  components: {
    [NavBar.name]: NavBar,
    [List.name]: List,
    [Dialog.Component.name]: Dialog.Component,
  },

  created() {
    // this.$nextTick(() => {
    //   // this.size = document.documentElement.style.fontSize;
    //   this.size = window.getComputedStyle(document.documentElement)["fontSize"];
    //   console.log(this.size);
    //   let showsize = this.size.split("p")[0];
    //   console.log(showsize);

    //   document.documentElement.style.fontSize = showsize / 3.125 + "px";
    // });
    this.getFoots();
  },

  // beforeDestroy() {
  //   document.documentElement.style.fontSize = this.size;
  // },

  computed: {
    ...mapGetters(["accessToken", "customerId"]),
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    onClickRight() {},
    // 分页获取数据
    getFoots() {
      // if (this.currentPage > this.totalPage) {
      //   this.loading = false;
      //   // 恢复currentPage和totalPage的值，以供分类分页使用
      //   return (this.finished = true);
      // }
      // 未登录的状态就不发起请求
      if (localStorage.getItem("token")) {
        this.$api.my
          .footPrintAddress({
            // accessToken: localStorage.getItem("token"),
            userId: localStorage.getItem("customerId"),
            storeId:localStorage.storeId,

            // currentPage: this.currentPage,
          })
          .then((res) => {
            if (res.errno === 200) {
              this.loading = false;
              this.footList = res.data;
              // if (res.returnValue) {
              //   this.totalPage = res.returnValue.totalPage;
              //   this.loading = false; // 关闭上拉加载中
              //   if (this.currentPage === 1) {
              //     this.footList = res.returnValue.rows;
              //   } else {
              //     this.footList = this.footList.concat(res.returnValue.rows);
              //   }
              //   this.currentPage++;
              // } else {
              //   this.loading = false;
              this.finished = true;
              // }
            } else {
              this.error = true;
            }
          });
      }
    },
    // show(item){
    //   console.log(item);
    // },
    // 上拉加载
    onLoad() {},
    //   // 异步获取数据
    //   setTimeout(() => {
    //     this.getFoots();
    //   }, 1000);
    // },
    // 删除足迹
    delFoots() {
      this.$api.my
        .delFootPrintAddress({
          userId: localStorage.getItem("customerId"),
        })
        .then((res) => {
          if (res.errno === 200) {
            this.footList = [];
          }
        });
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../../less/element.less";
.footprint {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 0.96rem;
  // background-color: #f0eded;
  // background-color: #F5F5F5;
  // background: #fff;
    .dark_bg{
      box-shadow: 0px 0px .2rem 0px rgba(20, 20, 20, 0.1);
    }
  .del-tip {
    width: 60%;
    .del-msg {
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      padding: 0.32rem;
      p {
        line-height: 0.64rem;
        img {
          width: 40%;
        }
      }
    }
    & /deep/ .van-dialog__footer {
      width: 100%;
      box-sizing: border-box;
      padding: 0 0.128rem 0.32rem;
      &::after {
        border: none;
      }
      .van-button {
        border: 1px solid #ce4434;
        border-radius: 0.96rem;
        letter-spacing: 1px;
        margin: 0 0.128rem;
        height: 0;
        line-height: 0;
        padding: 0.32rem 0;
        color: #ce4434;
        &.van-dialog__confirm {
          color: #fff;
          background-color: #ce4434;
        }
      }
    }
    & /deep/ .van-hairline--top {
      &::after {
        border: none;
      }
    }
  }
  .van-nav-bar {
    color: #373737;
    letter-spacing: 1px;
    margin: 0 0 0.32rem;
    .van-icon {
      color: #373737;
    }
  }
  .foot_list {
    // padding: 0.6 / 3.125rem;
    // .list(row);
    // flex-wrap: wrap;
    // justify-content: left;
    // padding-left: .15rem;
    // padding-bottom: .5rem;
      // background-color: #fff;
    ul {
      // background-color: #fff;
      // width: 30%;
      font-size: 0.29rem;
      padding-bottom: 0.12rem;
      margin:.4rem .15rem .0rem;
      width: 2.1rem;
      height: 2.06rem;
      .price{
          margin-top:.2rem;
          font-size: .28rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: @price_text_color;
          line-height: .3rem;
          .price-img{
            font-size: .22rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: @price_text_color;
            line-height: .3rem;
          }
        }
      li {
        // text-align: center;
        // line-height: 0.47rem;
        
        img {
          width: 100%;
          height: 1.58rem;
          border-radius: .1rem;

        }
        &.score {
          padding: 0.06rem;
          font-size: 0.16rem;
          color: #e1ac30;
          letter-spacing: 1px;
          display: -webkit-box;
          display: -moz-box;
          white-space: pre-wrap;
          word-wrap: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; /*显示行数*/
        }
      }
    }
  }
}
h2{
  margin: .4rem 0.3rem .3rem;
  font-size: .28rem;
font-family: PingFang SC;
font-weight: bold;
color: #333333;
line-height: .24rem;
}
// .data-center{
//     background-color: #F5F5F5;
// }
.data-list{
  display: flex;
  flex-wrap: wrap;
  padding: 0 .14rem;
}
.gap{
  width: 100%;
  height: .2rem;
}
 
</style>
